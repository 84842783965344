import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

Song of the day (_Canción del día_): _willow_ by Taylor Swift

La agenda de hoy:

- Repasar las primeras secciones de Unidad 3
- Hacer actividades en secciones E y F de Unidad 3

------------------
| Word | _Traducción_ |
| ---- | ----- |
| baby | _bebé_ |
| man | _hombre_ |
| men | _hombres_ |
| woman  | _mujer_ |
| women (wi-men) | _mujeres_ |
| son | _hijo_|
| daughter | _hija_ |
| grandfather | _abuelo_ |
| grandmother | _abuela_ |
| uncle | _tío_ |
| aunt | _tía_ |
| father | _padre_ |
| mother | _madre_ |
| brother | _hermano_ |
| sister | _hermana_ |
| boy | _niño_ |
| girl | _niña_ |
| teenager | _adolescente_ |
| wife | _esposa_ |
| husband | _esposo_ |

- hormiga = ant
- y -> ies: cuando la palabra termina en "y" y la penúltima letra es una consonante

| Singular | Plural |
| -- | --- |
| family (familia) | families (familias) |
| boy (niño) | boys (niños) |
| strawberry (fresa) | strawberries (fresas) |
| monkey (mono) | monkeys (monos)| 
| child (hijo/hija) | children (hijos)|

| to have | _tener_ |
| --- | --- |
| I have | Yo tengo | 
| You have | Tú tienes/ Ud. tiene/ Uds. tienen| 
| He has | Él tiene | 
| She has | Ella tiene | 
| We have | Nosotros tenemos |
| They have | Ellos tienen | 

Ejemplos:
- _Él tiene un hijo_ = He has a son
- _Ella tiene una hermana_ = She has a sister
- _Tienes una hija_ = You have a daughter
- _¿Tienes una hija?_ = Do you have a daughter?
	- Do: con I, you, we, they
		- Do they have a daughter? = _¿Ellos tienen una hija?_
	- Does: con he, she
		- Does she have a son? = _¿Ella tiene un hijo?_
- Negación:
	- She does not (doesn't) have a daughter = _Ella no tiene una hija_
	- You do not (don't) have a son = _Tú no tienes un hijo_
	- Don't you have a son? (Do you not have a son) = _¿No tienes un hijo?_
- Respuestas:
	- Yes, I do/No, I don't
	- Yes, she does/No, she doesn't (she/he)
	- Yes, we do/No, we don't
	- Yes, they do/No, they don't
- _¿Cuántos hijos tienes?_ = How many children do you have?
------------------
## Unas palabras opcionales 
| Word | _Traducción_ |
| ---- | ----- |
| nephew (nefyu)| _sobrino_ |
| niece (nis) | _sobrina_ |
| brother-in-law | _cuñado_ |
| sister-in-law | _cuñada_ |
| father-in-law | _suegro_ |
| mother-in-law | _suegra_ |
| son-in-law | _yerno_ |
| daughter-in-law | _nuera_ |
| in-laws | _suegros_ |
| stepfather | _padrastro_ |
| stepmother | _madrastra_ |
| stepbrother | _hermanastro_ |
| stepsister | _hermanastra_ |
| stepson | _hijastro_ |
| stepdaughter | _hijastra_ |
| godfather | _padrino_ |
| godmother | _madrina_ |
| cousin | _primo/prima_ |
| son's/daughter's godmother | _comadre_ |
| son's/daughter's godfather | _copadre_ |
| son's/daughter's father-in-law | _consuegro_ |
| son's/daughter's mother-in-law | _consuegra_ |
| older brother/sister | _hermano/hermana mayor_ |
| younger brother/sister | _hermano/hermana menor_ |
------------------
`

export default function Week02() {
	return (
		<div>
			<h1>Week 4 Lecture Notes (2/27)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			{/* <h2>Recursos</h2> */}
			{/* 	<ul> */}
			{/* 		<li><a href="https://www.berlitz.com/es-us/blog/miembros-de-la-familia-en-ingles">Miembros de la familia</a></li> */}
			{/* 		<li><a href="https://www.inglessencillo.com/el-plural">Formar el plural</a></li> */}
			{/* 	</ul> */}
		</div>
	)
}
