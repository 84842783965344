import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

Song of the day (_Canción del día_): _Graveyard_ by Halsey

Agenda de hoy:
- Repasar para el examen
	- Hay que entregarlo antes de la medianoche el __23 de marzo__ (el domingo)
	- Unidad 1-5
	- El coordinador les enviará el enlace para el examen después de esta clase por WhatsApp (más tarde esta noche o mañana por la mañana)

*******************************************************************************
## Unidad 1

| _Palabra_ | Traducción |
| --- | --- |
| número de teléfono | phone number |  
| apellido | last name | 
| código postal | ZIP code | 
| código de área | area code | 
| primer nombre  | first name | 
| firma | signature | 
| China | China | 
| los Estados Unidos | the United States| 
| Rusia | Russia | 
| México | Mexico | 
| Brasil | Brazil | 
| Haití | Haiti | 
| Somalia | Somalia | 
| Vietnam | Vietnam | 

- ¿Cómo te llamas? = What's your name?
- Me llamo = My name is 
- ¿Cómo se llama él? = What's his name?
- Él se llama = His name is 
- ¿Cómo se llama ella? = What's her name?
- Ella se llama = Her name is
- ¿De dónde eres? = Where are you from?
- Yo soy de = I am from
- ¿De dónde es él? = Where is he from?
- ¿De dónde es ella? = Where is she from?

| los días de la semana | days of the week | 
| ----- | ---- |
| lunes | Monday | 
| martes | Tuesday | 
| miércoles | Wednesday | 
| jueves | Thursday |
| viernes | Friday | 
| sábado | Saturday |
| domingo | Sunday | 

| los meses | months of the year | 
| ---- | ---- |
| enero | January | 
| febrero | February | 
| marzo | March | 
| abril | April | 
| mayo | May | 
| junio | June | 
| julio | July | 
| agosto | August | 
| septiembre | September | 
| octubre | October | 
| noviembre | November | 
| diciembre | December | 

- cumpleaños = birthday
- When's your birthday? = ¿Cuándo es tu cumpleaños?
*******************************************************************************
## Unidad 2
| _Palabra_ | Traducción |
| --- | --- |
| libro | a book | 
| cuaderno | a notebook | 
| escritorio | a desk | 
| lápiz | a pencil |
| borrador | an eraser |  
| papel | paper | 
| una hoja de papel | a sheet of paper | 
| silla | a chair | 
| regla | a ruler | 
| grapadora | a stapler | 
| computadora | a computer | 
| pluma | a pen | 
| diccionario | a dictionary | 

Preposiciones
- El diccionario está debajo del escritorio = The dictionary is under the desk
- La hoja de papel está dentro del libro = The sheet of paper is in the book
- El borrador está encima del escritorio =  The eraser is on the desk

Where is 
- ¿Dónde está ___ ? = Where is ___? (singular)
- ¿Dónde están ___ ? = Where are ___? (plural)

Fechas = Dates
- el 4 de julio = July 4<sup>th</sup> (fourth)
- el 3 de diciembre = December 3<sup>rd</sup> (third)
- el 25 de diciembre = December 25<sup>th</sup> (twenty-fifth)
- el 10 de febrero = February 10<sup>th</sup> (tenth)
- el 31 de octubre = October 31<sup>st</sup> (thirty-first)
*******************************************************************************

## Unidad 3

| _Palabra_ | Traducción |
| --- | --- |
| padre | father | 
| madre | mother | 
| hijo | son
| hija | daughter | 
| hermano | brother |
| hermana | sister |
| abuelo | grandfather |
| abuela | grandmother |
| tío | uncle |
| tía | aunt
esposo | husband |
| esposa | wife |
adolescente | teenager |
| bebé | baby
| hombre | man |
| hombres | men |
| mujer | woman| 
| mujeres | women | 
| niño | boy |


| to have | _tener_ |
| --- | --- |
| I have | Yo tengo | 
| You have | Tú tienes/ Ud. tiene/ Uds. tienen| 
| He has | Él tiene | 
| She has | Ella tiene | 
| We have | Nosotros tenemos |
| They have | Ellos tienen |


- Tengo un hermano = I have a brother
- Tengo una tía = I have an aunt
- Él tiene un tío = He has an uncle
- Mi tío tiene dos hijos = My uncle has two sons
- ¿Tienes una hermana? = Do you have a sister?
	- Yes, I do/ No, I don't
- ¿Ella tiene una hija? = Does she have a daughter?
    - Yes, she does/ No, she doesn't
*******************************************************************************
## Unidad 4

|_Palabra_| Word |
|:-----|:-----|
| paciente | patient | 
| doctor | doctor | 
| consultorio | doctor's office | 
| medicina | medicine | 
| enfermero | nurse | 

|_Partes del cuerpo_| Parts of the Body |
|:-----|:-----|
| pie | foot | 
| pies | feet | 
| mano | hand | 
| brazo | arm | 
| garganta | throat | 
| estómago | stomach | 
| pierna | leg | 
| ojo | eye | 
| cabeza | head | 
| diente | tooth |
| dientes | teeth |

- ¿Qué te duele? = What hurts? 
- Me duele el brazo = My arm hurts. 
- Me duelen los brazos = My arms hurt.

|_Palabra_| Word |
|:-----|:-----|
| dolor de cabeza | headache | 
| dolor de estómago | stomachache | 
| dolor de muelas | toothache | 
| dolor de garganta | sore throat | 
| fiebre | fever |  
| resfriado | cold | 
*******************************************************************************
## Unidad 5
|_Palabra_| Word |
|:-----|:-----|
| restaurante | restaurant | 
| calle | street | 
| biblioteca | library | 
| banco | bank | 
| panadería | bakery | 
| hospital | hospital | 
| escuela | school |
| tienda | store | 
| supermercado | supermarket | 
| farmacia | pharmacy |
| cine | movie theater | 
| gasolinera | gas station | 
| lavandería | laundromat | 
| oficina de correo | post office | 
| peluquería | barbershop | 

- La panadería está enfrente de la biblioteca = The bakery is across from the library
- El hospital está cerca de la escuela = The hospital is near the school
- La farmacia está al lado del supermercado = The pharmacy is next to the supermarket 
- El banco está en la calle Main = The bank is on Main Street. 
- El restaurante está en medio de la farmacia y el supermercado = The restaurant is between the pharmacy and the supermarket

|Español | English |
|:-----|:-----|
| en bicicleta | by bicycle (bike) | 
| por tren | by train | 
| taxi | by taxi | 
| a pie | on foot | 
| en carro | by car |
| en autobús | by bus | 

*******************************************************************************
## Good luck! = ¡Buena suerte!
*******************************************************************************

`

export default function Week02() {
	return (
		<div>
			<h1>Week 7 Lecture Notes (3/20)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			{/* <h2>Recursos</h2> */}
			{/* <p> */}
			{/* 	<ul> */}
			{/* 		<li><a href="https://englishlive.ef.com/es-mx/blog/tips-para-estudiar/reglas-de-fonetica-para-leer-y-escribir-hablar/">La "e" muda</a></li> */}
			{/* 	</ul> */}
			{/* </p> */}
		</div>
	)
}
